import * as React from 'react';

import { ThemeProvider as OriginalThemeProvider } from 'styled-components';
import { selectTheme } from './slice/selectors';
import { useSelector } from 'react-redux';
import { useThemeSlice } from './slice';
import { ConfigProvider } from 'antd';

export const ThemeProvider = (props: { children: React.ReactChild }) => {
  useThemeSlice();

  const theme = useSelector(selectTheme);
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: theme.primary,
          borderRadius: 4,
          fontSize: 14,
          // fontFamily: 'Source Sans Pro, sans-serif',
          fontFamily: 'Montserrat, sans-serif',
        },
      }}
    >
      <OriginalThemeProvider theme={theme}>
        {React.Children.only(props.children)}
      </OriginalThemeProvider>
    </ConfigProvider>
  );
};
